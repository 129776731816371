<template>
  <div
    id="admin"
    :class="[{ 'collapsed' : collapsed }, { 'onmobile' : isOnMobile }]">
    <div class="admin">
      <!-- Header -->
      <div
        class="admin-header"
        :class="[collapsed ? 'extended' : 'normal']">
        <b-row>
          <b-col class="my-auto" cols="6">
            <router-link to="/admin">
              <img
                class="logo"
                src="../../assets/images/h3_logo.png"
                alt="Healthy Hip Hop Logo"
              />
            </router-link>
          </b-col>
          <b-col class="text-right my-auto" cols="6">
            <span class="logout-link" @click="logout">Logout</span>
          </b-col>
        </b-row>
      </div>
      <!-- Container -->
      <div class="admin-container">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </div>

      <!-- Sidebar Menu -->
      <sidebar-menu
        :menu="menu"
        :collapsed="collapsed"
        :width="'280px'"
        :widthCollapsed="'50px'"
        :showOneChild="true"
        @toggle-collapse="onToggleCollapse"
      ></sidebar-menu>
      <div
        class="sidebar-overlay"
        v-if="isOnMobile && !collapsed"
        @click="collapsed = true"
      />
    </div>

    <AppLoaderAdmin :isLoading="loading" />
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loading    : false,
        collapsed  : false,
        isOnMobile : false,
        menu       : [
          {
            header           : true,
            title            : 'Administration',
            hiddenOnCollapse : true,
          },
          {
            href  : '/admin/dashboard',
            title : 'Dashboard',
            icon  : 'fa fa-chart-area',
          },
          // {
          //   href  : '/admin/featured',
          //   title : 'Featured',
          //   icon  : 'fas fa-star',
          // },
          // {
          //   href  : '/admin/categories',
          //   title : 'Categories',
          //   icon  : 'fas fa-object-group',
          //   child : [
          //     {
          //       href  : '/admin/categories/interact',
          //       title : 'Interact',
          //     },
          //   ],
          // },
          {
            title : 'Accounts',
            icon  : 'fa fa-users',
            child : [
              {
                href  : '/admin/users',
                title : 'Users',
              },
              {
                href  : '/admin/influencers',
                title : 'Influencers',
              },
              {
                href  : '/admin/enterprise',
                title : 'Enterprise',
              },
              {
                href  : '/admin/h3-characters',
                title : 'H3 Characters',
              },
            ],
          },
          {
            title : 'Albums',
            icon  : 'fas fa-music',
            child : [
              {
                href  : '/admin/music-albums/create',
                title : 'Create Music Album',
              },
              {
                href  : '/admin/music-albums',
                title : 'Music Albums',
              },
              // {
              //   href  : '/admin/challenges-albums/create',
              //   title : 'Create Challenges Album',
              // },
              // {
              //   href  : '/admin/challenges-albums',
              //   title : 'Challenges Albums',
              // },
               {
                href  : '/admin/playlist/1',
                title : 'Playlist',
              },
            ],
          },
          {
            title : 'Videos',
            icon  : 'fas fa-video',
            child : [
              {
                href  : '/admin/videos',
                title : 'Videos'
              },
              {
                href  : '/admin/videos/upload',
                title : 'Upload videos',
              },
            ],
          },
          {
            href  : '/admin/announcements',
            title : 'Announcements',
            icon  : 'fas fa-bullhorn',
          },
          {
            href  : '/admin/live-broadcast',
            title : 'Live Broadcast',
            icon  : 'fas fa-broadcast-tower',
          },
          // {
          //   href  : '/admin/mobile-filter-assets',
          //   title : 'Mobile Filter Assets',
          //   icon  : 'fas fa-theater-masks',
          // },
          {
            href  : '/admin/moderate-content',
            title : 'Moderate Content',
            icon  : 'fas fa-flag',
          },
          // {
          //   href  : '/admin/playlist/1',
          //   title : 'Playlist',
          //   icon  : 'fas fa-stream',
          // },
          {
            href  : '/admin/web-settings',
            title : 'Web Settings',
            icon  : 'fas fa-cog',
            class : 'web-settings',
          },
        ],
      }
    },
    components : {
      'AppLoaderAdmin' : () => import('@/components/layout/AppLoaderAdmin'),
    },
    methods : {

      /**
       * On Sidebar Toggle
       * @param collapsed
       */
      onToggleCollapse(collapsed) {
        this.collapsed = collapsed;
      },


      /**
       * On Window Resize
       */
      onResize () {
        if (window.innerWidth <= 767) {
          this.isOnMobile = true;
          this.collapsed = true;
        } else {
          this.isOnMobile = false;
          this.collapsed = false;
        }
      },

      /**
       * Logout
       */
      logout() {
        this.loading = true;
        this.$http.post('/auth/logout', {
          accessToken  : localStorage.accessToken,
          refreshToken : localStorage.refreshToken,
        }).then(() => {
          window.localStorage.clear();
          this.$router.replace('/admin/login');
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }).finally(() => {
          this.loading = false;
        });
      },
    },
    mounted() {
      this.onResize();
      window.addEventListener('resize', this.onResize);

      this.$store.dispatch('playlist/getRecentSongs');
    },
  }
</script>

<style lang="scss" scoped>
  @import '../../assets/scss/components/admin/main2';
</style>
